const VersionInfo = {
  version: {
    release: "1.0.6",
    releasedate: "2025-01-02",
    frondendversion: "1.0.6",
    backendversion: "1.0.6",
    environment: "Test for new functions",
    name: "vwgidsapp",
    description: "A description of your application",
    author: "Tijmen V. Baas",
    license: "MIT",
  },
};

export default VersionInfo;
