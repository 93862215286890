import React, { useMemo } from "react";
import ResponsiveDrawer from "./components/Sidebar";
import Homepage from "./pages/Homepage";
import { Routes, Route } from "react-router-dom";
import Families from "./pages/Families";
import MijnBegeleiding from "./pages/MijnBegeleiding";
import Kennisbank from "./pages/Kennisbank";
import KennisbankArticle from './pages/KennisbankArticle';
import NotFound from "./pages/404";
import Onderhoud from "./pages/Onderhoud";
import Users from "./maintenance/Users";
import Trajectories from "./maintenance/Trajectories";
import Dienstverleners from "./maintenance/Dienstverleners";
import Tasklist from "./maintenance/Tasklist";
import NewFamily from "./forms/NewFamily";
import FamilieOverview from "./pages/FamilieOverview";
import NewFamilyMember from "./forms/NewFamilyMember";
import FamilyLogboek from "./pages/FamilyLogboek";
import FamilyFiles from "./pages/FamilyFiles";
import Login from "./pages/Login";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./components/Theme";
import "./fonts/Changa-Regular.ttf";

import Spinner from "./components/Spinner";

import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import PrivateRouters from "./utils/PrivateRouters";
import FamilyMemberChecklist from "./forms/FamilyMemberChecklist";
import EditFamilyMember from "./forms/EditFamilyMember";
import EditFamily from "./forms/EditFamily";
import FamilyMemberAccounts from "./forms/FamilyMemberAccounts";

const theme = Theme;

function App() {
  const { login, result, error } = useMsalAuthentication(InteractionType.Popup);
  
  // Memoize the routes to prevent unnecessary re-renders
  const routes = useMemo(() => (
    <Routes>
      <Route path="/login" element={<Login theme={theme} />} />
      <Route path="/" element={<Homepage theme={theme} />} />

      <Route element={<PrivateRouters />}>
        <Route
          path="/mijnbegeleiding"
          element={<MijnBegeleiding theme={theme} />}
        />
        <Route path="/families" element={<Families theme={theme} />} />
        <Route path="/kennisbank" element={<Kennisbank theme={theme} />} />
        <Route path="/kennisbank/article/new" element={<KennisbankArticle theme={theme} />} />
        <Route path="/kennisbank/article/:id" element={<KennisbankArticle theme={theme} />} />
        <Route path="/onderhoud" element={<Onderhoud theme={theme} />} />
        <Route
          path="/onderhoud/gebruikers"
          element={<Users theme={theme} />}
        />
        <Route
          path="/onderhoud/trajecten"
          element={<Trajectories theme={theme} />}
        />
        <Route
          path="/onderhoud/dienstverleners"
          element={<Dienstverleners theme={theme} />}
        />
        <Route
          path="/onderhoud/takenlijst"
          element={<Tasklist theme={theme} />}
        />
        <Route path="/newfamily" element={<NewFamily theme={theme} />} />

        <Route path="/editfamily" element={<EditFamily theme={theme} />} />
        <Route
          path="/familymemberaccounts"
          element={<FamilyMemberAccounts theme={theme} />}
        />
        <Route
          path="/newfamilymember"
          element={<NewFamilyMember theme={theme} />}
        />
        <Route
          path="/familycard/editfamilymember"
          element={<EditFamilyMember theme={theme} />}
        />
        <Route
          path="/memberchecklist"
          element={<FamilyMemberChecklist theme={theme} />}
        />
        <Route
          path="/familycard"
          element={<FamilieOverview theme={theme} />}
        />
        <Route
          path="/family_logboek"
          element={<FamilyLogboek theme={theme} />}
        />
        <Route
          path="/family_files"
          element={<FamilyFiles theme={theme} />}
        />
      </Route>
      <Route path="*" element={<NotFound theme={theme} />} />
    </Routes>
  ), []); // Empty dependency array since theme is constant

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ResponsiveDrawer theme={theme} />
        {routes}
      </ThemeProvider>
    </div>
  );
}

export default React.memo(App);
