import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, IconButton } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import axios from "axios";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

function Families() {
  const [families, setFamilies] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  const { instance, accounts } = useMsal();

  const GetAccessTokens = async () => {
    const request = {
      scopes: ["api://e8ee6ebf-41ff-4a7a-8d4b-2b2b4aa05d8e/User.Read"],
      account: accounts[0],
    };
    console.log(request);
    try {
      const response = await instance.acquireTokenSilent(request);
      console.log(response);
      return response.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(request);
      }
    }
  };
  const getAccessTokens = GetAccessTokens();

  const fetchData = async () => {
    const token = await getAccessTokens;
    console.log(token);
    axios({
      method: "GET",
      url: "/api/families",
      headers: { Authorization: "Bearer " + token },
    }).then((response) => {
      setFamilies(response.data.families);
      sleep(1000);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleExportToExcel = async () => {
    try {
      const token = await getAccessTokens;
      const response = await fetch("/api/export-my-families", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to export families");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Mijn_clienten_${new Date().toISOString().split("T")[0]}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error exporting families:", error);
    }
  };

  return (
    <Container>
      {isloading ? (
        <Spinner message={"Familiegegevens ophalen..."} />
      ) : (
        <Content props={{ families, accounts }} handleExportToExcel={handleExportToExcel} />
      )}
    </Container>
  );
}

export default Families;

const StyledTableCell = TableCell;

const StyledTableRow = TableRow;

const Content = ({ props, handleExportToExcel }) => {
  const navigate = useNavigate();
  const handleDoubleClick = (client) => {
    navigate("/familycard", { state: { from: client } });
  };
  const filteredFamilies = props.families.filter(
    (family) => family.mb_email === props.accounts[0].username.toLowerCase()
  );
  return (
    <React.Fragment>
      <Box sx={{ maxWidth: "100%", mt: 10 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Mijn families in begeleiding
        </Typography>
        <Typography variant="body1" color="initial" sx={{ marginTop: 2 }}>
          Dubbelklik op een familie om naar de familiegegevens te gaan.
        </Typography>
        <IconButton color="success" component={Link} to="/newfamily">
          <PersonAddAltIcon /> Family toevoegen
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          onClick={handleExportToExcel}
        >
          Exporteer naar Excel
        </Button>
      </Box>
      <TableContainer component={Paper} elevation={3}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>FamilieId</StyledTableCell>
              <StyledTableCell>Familie naam</StyledTableCell>
              <StyledTableCell>Straat</StyledTableCell>
              <StyledTableCell>Huisnummer</StyledTableCell>
              <StyledTableCell>Postcode</StyledTableCell>
              <StyledTableCell>Plaats</StyledTableCell>
              <StyledTableCell>Begeleider</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {filteredFamilies.map((row) => (
              <StyledTableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onDoubleClick={() => handleDoubleClick(row)}
                hover
              >
                <StyledTableCell>{row.id}</StyledTableCell>
                <StyledTableCell align="left">{row.familyName}</StyledTableCell>
                <StyledTableCell align="left">{row.familyStreet}</StyledTableCell>
                <StyledTableCell align="left">{row.familyHousenumber}</StyledTableCell>
                <StyledTableCell align="left">{row.familyPostalcode}</StyledTableCell>
                <StyledTableCell align="left">{row.familyCity}</StyledTableCell>
                <StyledTableCell align="left">{row.first_nameMB}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
