import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Paper,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  Stack,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { useAuth } from "../authentication/useAuth";
import { useMsal } from "@azure/msal-react";
import { useNavigate, Link } from "react-router-dom";

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

function Kennisbank() {
  const navigate = useNavigate();
  console.log("navigate function:", navigate); // Debug navigate
  const { getAccessToken } = useAuth();
  const { instance, accounts } = useMsal();
  const user = accounts[0];
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openNewArticleDialog, setOpenNewArticleDialog] = useState(false);
  const [error, setError] = useState(null);
  const [categoryForm, setCategoryForm] = useState({
    categorienaam: "",
    categorieomschrijving: "",
  });
  const [articleForm, setArticleForm] = useState({
    titel: "",
    category_id: "",
    korte_samenvatting: "",
    uitgebreide_tekst: "",
    externe_links: "",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    fetchArticles();
    fetchCategories();
  }, []);

  const fetchArticles = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch("/api/kennisbank/articles", {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch articles");
      }

      const data = await response.json();
      setArticles(data.map(article => ({
        ...article,
        korte_samenvatting: article.korte_samenvatting || (article.details && article.details.korte_samenvatting) || '',
        uitgebreide_tekst: article.uitgebreide_tekst || (article.details && article.details.uitgebreide_tekst) || '',
        externe_links: article.externe_links || (article.details && article.details.externe_links) || '',
        files: article.files || []
      })));
    } catch (error) {
      console.error("Error fetching articles:", error);
      setError(error.message);
    }
  };

  const fetchCategories = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch("/api/kennisbank/categories", {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }

      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError('Failed to fetch categories');
    }
  };

  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessToken();
      const response = await fetch("/api/kennisbank/categories", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(categoryForm),
      });

      if (!response.ok) {
        throw new Error('Failed to create category');
      }

      await fetchCategories();
      setOpenCategoryDialog(false);
      setCategoryForm({ categorienaam: "", categorieomschrijving: "" });
    } catch (error) {
      console.error("Error creating category:", error);
      setError('Failed to create category');
    }
  };

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleFileUpload = async (articleId) => {
    if (!selectedFiles.length) return;

    try {
      const token = await getAccessToken();
      
      // Upload each file individually
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(`/api/kennisbank/upload/${articleId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file: ' + file.name);
        }
      }

      setSelectedFiles([]);
    } catch (error) {
      console.error('Error uploading files:', error);
      setError('Failed to upload files');
    }
  };

  const handleNewArticleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessToken();
      const formData = new FormData();
      formData.append('article', JSON.stringify(articleForm));

      const response = await fetch("/api/kennisbank/articles", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to create article');
      }

      const data = await response.json();
      
      // Upload files if any were selected
      if (selectedFiles.length > 0) {
        await handleFileUpload(data.id);
      }

      await fetchArticles();
      setOpenNewArticleDialog(false);
      setArticleForm({
        titel: "",
        category_id: "",
        korte_samenvatting: "",
        uitgebreide_tekst: "",
        externe_links: "",
      });
      setSelectedFiles([]);
      
      // Navigate to the edit page of the newly created article
      navigate(`/kennisbank/article/${data.id}`);
    } catch (error) {
      console.error("Error creating article:", error);
      setError('Failed to create article');
    }
  };

  const handleRowClick = (article) => {
    navigate(`/kennisbank/article/${article.id}`);
  };

  const filteredArticles = articles.filter(article => {
    const matchesSearch = (
      article.titel.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.korte_samenvatting.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const matchesCategory = selectedCategory === "all" || article.category_id === parseInt(selectedCategory);
    
    return matchesSearch && matchesCategory;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container sx={{ mt: 10 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 4 }}>
        <Typography variant="h4" component="h1">
          Kennisbank
        </Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => setOpenCategoryDialog(true)}
            sx={{ mr: 2 }}
          >
            Nieuwe Categorie
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenNewArticleDialog(true)}
          >
            Nieuw Artikel
          </Button>
        </Box>
      </Box>

      <SearchBar>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Zoeken..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
          }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Categorie</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            label="Categorie"
          >
            <MenuItem value="all">Alle Categorieën</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.categorienaam}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SearchBar>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Titel</TableCell>
              <TableCell>Categorie</TableCell>
              <TableCell>Korte Samenvatting</TableCell>
              <TableCell>Bijlagen</TableCell>
              <TableCell>Laatst Bijgewerkt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredArticles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((article) => (
                <TableRow
                  key={article.id}
                  hover
                  onClick={() => handleRowClick(article)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{article.titel}</TableCell>
                  <TableCell>
                    <Chip
                      label={categories.find(c => c.id === article.category_id)?.categorienaam || 'Unknown'}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{article.korte_samenvatting}</TableCell>
                  <TableCell>
                    {article.files && article.files.length > 0 && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AttachFileIcon fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">
                          {article.files.length}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {article.datum_laatste_update ? new Date(article.datum_laatste_update).toLocaleString('nl-NL', {
                      year: 'numeric',
                      month: '2-digit', 
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                    }) : 'Niet beschikbaar'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredArticles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>

      {/* Category Dialog */}
      <Dialog open={openCategoryDialog} onClose={() => setOpenCategoryDialog(false)}>
        <DialogTitle>Nieuwe Categorie</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Categorienaam"
            fullWidth
            value={categoryForm.categorienaam}
            onChange={(e) => setCategoryForm({ ...categoryForm, categorienaam: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Omschrijving"
            fullWidth
            multiline
            rows={4}
            value={categoryForm.categorieomschrijving}
            onChange={(e) => setCategoryForm({ ...categoryForm, categorieomschrijving: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCategoryDialog(false)}>Annuleren</Button>
          <Button onClick={handleCategorySubmit} variant="contained">
            Opslaan
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Article Dialog */}
      <Dialog 
        open={openNewArticleDialog} 
        onClose={() => setOpenNewArticleDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Nieuw Artikel</DialogTitle>
        <form onSubmit={handleNewArticleSubmit}>
          <DialogContent>
            <TextField
              fullWidth
              label="Titel"
              value={articleForm.titel}
              onChange={(e) => setArticleForm({ ...articleForm, titel: e.target.value })}
              required
              margin="normal"
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Categorie</InputLabel>
              <Select
                value={articleForm.category_id}
                onChange={(e) => setArticleForm({ ...articleForm, category_id: e.target.value })}
                label="Categorie"
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.categorienaam}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Korte Samenvatting"
              value={articleForm.korte_samenvatting}
              onChange={(e) => setArticleForm({ ...articleForm, korte_samenvatting: e.target.value })}
              required
              multiline
              rows={2}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Uitgebreide Tekst"
              value={articleForm.uitgebreide_tekst}
              onChange={(e) => setArticleForm({ ...articleForm, uitgebreide_tekst: e.target.value })}
              required
              multiline
              rows={4}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Externe Links"
              value={articleForm.externe_links}
              onChange={(e) => setArticleForm({ ...articleForm, externe_links: e.target.value })}
              margin="normal"
            />
            
            {/* File Upload Section */}
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Bijlagen
              </Typography>
              <Button
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                sx={{ mt: 1 }}
              >
                Bestanden Selecteren
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
              </Button>
              {selectedFiles.length > 0 && (
                <List dense>
                  {selectedFiles.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={file.name}
                        secondary={`${(file.size / 1024).toFixed(2)} KB`}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setOpenNewArticleDialog(false);
              setSelectedFiles([]);
            }}>
              Annuleren
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Opslaan
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
}

export default Kennisbank;
