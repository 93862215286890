import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  TextField,
  Paper,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Breadcrumbs,
  Link,
  CircularProgress,
  Fab,
  Chip,
  FormHelperText,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from "../authentication/useAuth";
import { useMsal } from "@azure/msal-react";

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  '& .MuiTextField-root': {
    marginBottom: theme.spacing(2),
  },
}));

const ContentSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& h2': {
    marginBottom: theme.spacing(2),
  },
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  display: 'flex',
  gap: theme.spacing(2),
  zIndex: 1000,
}));

function KennisbankArticle() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAccessToken } = useAuth();
  const { instance, accounts } = useMsal();
  const user = accounts[0];

  const [article, setArticle] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isEditMode, setIsEditMode] = useState(id === 'new');
  const [loading, setLoading] = useState(id !== 'new'); // Only set loading true if fetching existing article
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);

  const [editForm, setEditForm] = useState({
    titel: "",
    korte_samenvatting: "",
    uitgebreide_tekst: "",
    externe_links: "",
    category_id: "",
  });

  useEffect(() => {
    const initializeComponent = async () => {
      try {
        await fetchCategories();
        if (id !== 'new') {
          await fetchArticle();
        }
      } catch (error) {
        console.error("Error initializing component:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    initializeComponent();
  }, [id]);

  const fetchArticle = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`/api/kennisbank/articles/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch article");
      }

      const data = await response.json();
      setArticle(data);
      setEditForm({
        titel: data.titel || "",
        korte_samenvatting: data.details?.korte_samenvatting || "",
        uitgebreide_tekst: data.details?.uitgebreide_tekst || "",
        externe_links: data.details?.externe_links || "",
        category_id: data.category_id || "",
      });
      setExistingFiles(data.files || []);
    } catch (error) {
      console.error("Error fetching article:", error);
      setError(error.message);
    }
  };

  const fetchCategories = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch("/api/kennisbank/categories", {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }

      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError('Failed to fetch categories');
    }
  };

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleFileUpload = async () => {
    if (!selectedFiles.length) return;

    try {
      const token = await getAccessToken();
      
      // Upload each file individually
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(`/api/kennisbank/upload/${id}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file: ' + file.name);
        }
      }

      // Refresh the article to get updated files
      await fetchArticle();
      setSelectedFiles([]);
    } catch (error) {
      console.error('Error uploading files:', error);
      setError('Failed to upload files');
    }
  };

  const handleFileDownload = async (fileId) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`/api/kennisbank/files/${fileId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      const contentDisposition = response.headers.get('content-disposition');
      let filename = 'download';
      
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      setError('Failed to download file');
    }
  };

  const handleFileDelete = async (fileId) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`/api/kennisbank/file/${fileId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete file');
      }

      // Refresh the article to get updated files
      await fetchArticle();
    } catch (error) {
      console.error('Error deleting file:', error);
      setError('Failed to delete file');
    }
  };

  const handleSave = async () => {
    try {
      const token = await getAccessToken();
      const method = id === 'new' ? 'POST' : 'PUT';
      const url = id === 'new' ? '/api/kennisbank/articles' : `/api/kennisbank/articles/${id}`;

      const formData = new FormData();
      formData.append('article', JSON.stringify({
        titel: editForm.titel,
        category_id: editForm.category_id,
        korte_samenvatting: editForm.korte_samenvatting,
        uitgebreide_tekst: editForm.uitgebreide_tekst,
        externe_links: editForm.externe_links,
      }));

      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to save article');
      }

      const data = await response.json();
      
      // If this is a new article, redirect to the edit page
      if (id === 'new') {
        navigate(`/kennisbank/article/${data.id}`);
        return;
      }

      // Upload any new files
      if (selectedFiles.length > 0) {
        await handleFileUpload();
      }

      setIsEditMode(false);
      await fetchArticle();
    } catch (error) {
      console.error('Error saving article:', error);
      setError('Failed to save article');
    }
  };

  if (loading) {
    return (
      <Container sx={{ mt: 10, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 10 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 10, mb: 8 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs sx={{ mb: 3 }}>
        <Link
          color="inherit"
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/kennisbank')}
        >
          Kennisbank
        </Link>
        <Typography color="text.primary">
          {id === 'new' ? 'Nieuw Artikel' : (isEditMode ? 'Bewerk Artikel' : article?.titel)}
        </Typography>
      </Breadcrumbs>

      {/* Main content */}
      <StyledPaper elevation={3}>
        {/* Title Section */}
        <ContentSection>
          {isEditMode ? (
            <>
              <TextField
                fullWidth
                label="Titel"
                value={editForm.titel}
                onChange={(e) => setEditForm({ ...editForm, titel: e.target.value })}
                required
                error={!editForm.titel}
                helperText={!editForm.titel && "Titel is verplicht"}
              />
              <FormControl fullWidth sx={{ mt: 2 }} required error={!editForm.category_id}>
                <InputLabel>Categorie</InputLabel>
                <Select
                  value={editForm.category_id}
                  onChange={(e) => setEditForm({ ...editForm, category_id: e.target.value })}
                  label="Categorie"
                >
                  <MenuItem value="">
                    <em>Selecteer een categorie</em>
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.categorienaam}
                    </MenuItem>
                  ))}
                </Select>
                {!editForm.category_id && (
                  <FormHelperText>Categorie is verplicht</FormHelperText>
                )}
              </FormControl>
            </>
          ) : (
            <>
              <Typography variant="h4" component="h1" gutterBottom>
                {article?.titel || "Nieuw Artikel"}
              </Typography>
              {article?.category_id && (
                <Chip
                  label={categories.find(c => c.id === article?.category_id)?.categorienaam}
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{ mb: 2 }}
                />
              )}
            </>
          )}
        </ContentSection>

        {/* Summary Section */}
        <ContentSection>
          <Typography variant="h6" gutterBottom>
            Samenvatting
          </Typography>
          {isEditMode ? (
            <TextField
              fullWidth
              multiline
              rows={3}
              value={editForm.korte_samenvatting}
              onChange={(e) => setEditForm({ ...editForm, korte_samenvatting: e.target.value })}
            />
          ) : (
            <Typography variant="body1" paragraph>
              {article?.details?.korte_samenvatting}
            </Typography>
          )}
        </ContentSection>

        {/* Detailed Text Section */}
        <ContentSection>
          <Typography variant="h6" gutterBottom>
            Uitgebreide Tekst
          </Typography>
          {isEditMode ? (
            <TextField
              fullWidth
              multiline
              rows={6}
              value={editForm.uitgebreide_tekst}
              onChange={(e) => setEditForm({ ...editForm, uitgebreide_tekst: e.target.value })}
            />
          ) : (
            <Typography variant="body1" paragraph>
              {article?.details?.uitgebreide_tekst}
            </Typography>
          )}
        </ContentSection>

        {/* External Links Section */}
        <ContentSection>
          <Typography variant="h6" gutterBottom>
            Externe Links
          </Typography>
          {isEditMode ? (
            <TextField
              fullWidth
              multiline
              rows={3}
              value={editForm.externe_links}
              onChange={(e) => setEditForm({ ...editForm, externe_links: e.target.value })}
              helperText="Voer elke link op een nieuwe regel in"
            />
          ) : (
            <Box component="div" sx={{ mb: 2 }}>
              {article?.details?.externe_links?.split('\n').map((link, index) => (
                link?.trim() && (
                  <Link
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    display="block"
                    sx={{ mb: 1 }}
                  >
                    {link}
                  </Link>
                )
              ))}
            </Box>
          )}
        </ContentSection>

        {/* Attachments Section */}
        <ContentSection>
          <Typography variant="h6" gutterBottom>
            Bijlagen
          </Typography>
          {isEditMode && (
            <Box sx={{ mb: 2 }}>
              <Button
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                sx={{ mb: 2 }}
              >
                Bestand Toevoegen
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
              </Button>
              {selectedFiles.length > 0 && (
                <Typography variant="body2" color="text.secondary">
                  {selectedFiles.length} nieuwe bestand(en) geselecteerd
                </Typography>
              )}
            </Box>
          )}
          <List>
            {existingFiles.map((file) => (
              <ListItem key={file.id}>
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                <ListItemText
                  primary={file.filename}
                  secondary={file.upload_date}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleFileDownload(file.id)}
                    sx={{ mr: 1 }}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  {isEditMode && (
                    <IconButton
                      edge="end"
                      onClick={() => handleFileDelete(file.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </ContentSection>
      </StyledPaper>

      {/* Floating Action Buttons */}
      <ActionButtons>
        <Fab
          color="primary"
          onClick={() => navigate('/kennisbank')}
          sx={{ mr: 1 }}
        >
          <ArrowBackIcon />
        </Fab>
        {isEditMode ? (
          <>
            <Fab
              color="secondary"
              onClick={() => setIsEditMode(false)}
              sx={{ mr: 1 }}
            >
              <CloseIcon />
            </Fab>
            <Fab
              color="primary"
              onClick={handleSave}
            >
              <SaveIcon />
            </Fab>
          </>
        ) : (
          <Fab
            color="primary"
            onClick={() => setIsEditMode(true)}
          >
            <EditIcon />
          </Fab>
        )}
      </ActionButtons>
    </Container>
  );
}

export default KennisbankArticle;
